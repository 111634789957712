import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { postNewDepara } from "../../../../../../Controllers/CustomScreens/Unifacisa/DeparaController";
import { CreateDeparaSchema, CreateDeparaSchemaProps } from "./schema";

interface UseCreateDeparaProps {
  handleCloseModal: () => void;
  handleRefreshDeparas: () => void;
}
export const useCreateDepara = ({
  handleCloseModal,
  handleRefreshDeparas,
}: UseCreateDeparaProps) => {
  const methods = useForm<CreateDeparaSchemaProps>({
    resolver: zodResolver(CreateDeparaSchema),
  });

  const isDisabled = methods.watch("tp_depara");

  async function handleSubmitForm(data: CreateDeparaSchemaProps) {
    try {
      await postNewDepara(data);
      toast.success("Depara criado com sucesso!");
      handleRefreshDeparas();
      handleCloseModal();
    } catch (err) {
      toast.error("Erro na criação, verifique os valores informados!");
    }
  }

  return {
    methods,
    handleSubmitForm,
    isDisabled,
  };
};
