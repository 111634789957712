import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  deleteDepara,
  getAllDeparas,
} from "../../../../Controllers/CustomScreens/Unifacisa/DeparaController";

export interface DeparaProps {
  CD_DEPARA_INTEGRA: string;
  CD_DEPARA_MV: string;
  CD_MULTI_EMPRESA: number;
  CD_SISTEMA_INTEGRA: string;
  DS_DEPARA_INTEGRA: null;
  DS_DEPARA_MV: string;
  SN_AUTOMATICO: string;
  TP_DEPARA: string;
  CD_DTI_DEPARA: number;
  SN_ATIVO: string;
}
interface DeparasListProps {
  data: DeparaProps[];
  limit: string;
  page: string;
  status: number;
  total: number;
}

interface DeparaFiltersProps {
  tp_depara?: string;
  cd_multi_empresa?: number;
  cd_depara_mv?: string;
  cd_depara_integra?: string;
  page?: number;
}

export const useDepara = () => {
  const [dialogCreate, setDialogCreate] = useState<boolean>(false);
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false);
  const [deparasList, setDeparasList] = useState<DeparasListProps>(
    {} as DeparasListProps
  );
  const [deparaFilters, setDeparaFilters] = useState<DeparaFiltersProps>(
    {} as DeparaFiltersProps
  );

  const [updateDeparaObject, setUpdateDeparaObject] = useState<DeparaProps>(
    {} as DeparaProps
  );

  async function handleGetDeparas(page?: number) {
    try {
      const { data } = await getAllDeparas(
        deparaFilters.tp_depara,
        deparaFilters.cd_multi_empresa,
        deparaFilters.cd_depara_mv,
        deparaFilters.cd_depara_integra,
        deparaFilters.page || page
      );
      setDeparasList(data);
    } catch (err) {}
  }

  function handleUpdateDepara(data: DeparaProps) {
    setUpdateDeparaObject(data);
    setDialogUpdate(true);
  }

  async function handleDeleteDepara(cd_dti_depara: number) {
    try {
      await deleteDepara(cd_dti_depara);

      toast.success("Registro deletado com sucesso");
      await handleGetDeparas();
    } catch (err) {}
  }

  async function handlePaginate(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    await handleGetDeparas(value);
  }

  useEffect(() => {
    if (deparasList?.data?.length <= 0 || deparasList?.data === undefined) {
      handleGetDeparas();
    }
  }, []);

  return {
    dialogCreate,
    setDialogCreate,
    dialogUpdate,
    setDialogUpdate,
    deparasList,
    setDeparaFilters,
    handlePaginate,
    handleGetDeparas,
    handleDeleteDepara,
    handleUpdateDepara,
    updateDeparaObject,
  };
};
