import { RxCross1 } from "react-icons/rx";
import "./styles.scss";
import { useCreateDepara } from "./useCreateDepara";
interface CreateDeparaProps {
  handleCloseModal: () => void;
  handleRefreshDeparas: () => void;
}
export function CreateDepara({
  handleCloseModal,
  handleRefreshDeparas,
}: CreateDeparaProps) {
  const { methods, handleSubmitForm, isDisabled } = useCreateDepara({
    handleCloseModal,
    handleRefreshDeparas,
  });
  return (
    <div className="dialog-create-depara-container">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <main>
          <div className="dialog-input-group-normal">
            <p>tp_depara </p>
            <select {...methods.register("tp_depara")}>
              <option selected hidden value="">
                Tipo de depara
              </option>
              <option>CONTA_CONTABIL_RM</option>
              <option>CENTRO_CUSTO_RM</option>
              <option>HISTORICO_RM</option>
            </select>
          </div>
          <div className="dialog-input-group-small">
            <p>cd_multi_empresa </p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("cd_multi_empresa")}
            />
          </div>
          <div className="dialog-input-group-small">
            <p>cd_depara_mv </p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("cd_depara_mv")}
              className={
                methods.formState?.errors?.cd_depara_mv ? "input-error" : ""
              }
            />
          </div>
          <div className="dialog-input-group-small">
            <p>ds_depara_mv </p>
            <input
              type="text"
              autoComplete="off"
              disabled={isDisabled !== "CONTA_CONTABIL_RM"}
              {...methods.register("ds_depara_mv")}
            />
          </div>
          <div className="dialog-input-group-small">
            <p>cd_depara_integra </p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("cd_depara_integra")}
              className={
                methods.formState?.errors?.cd_depara_integra
                  ? "input-error"
                  : ""
              }
            />
          </div>
          <div className="dialog-input-group-small">
            <p>ds_depara_integra </p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("ds_depara_integra")}
            />
          </div>
          <div className="dialog-input-group-small">
            <p>cd_sistema_integra </p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("cd_sistema_integra")}
              disabled
              value="RM"
            />
          </div>
        </main>
        <footer>
          <button type="submit">Confirmar</button>

          <button type="button" onClick={() => handleCloseModal()}>
            Cancelar
          </button>
        </footer>
      </form>
    </div>
  );
}
