import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateDepara } from "../../../../../../Controllers/CustomScreens/Unifacisa/DeparaController";
import { DeparaProps } from "../../useDeparas";
import { UpdateDeparaSchema, UpdateDeparaSchemaProps } from "./schema";

interface UseUpdateDeparaProps {
  handleCloseModal: () => void;
  deparaData: DeparaProps;
  handleRefreshDeparas: () => void;
}
export const useUpdateDepara = ({
  deparaData,
  handleCloseModal,
  handleRefreshDeparas,
}: UseUpdateDeparaProps) => {
  const methods = useForm<UpdateDeparaSchemaProps>({
    resolver: zodResolver(UpdateDeparaSchema),
    defaultValues: {
      tp_depara: deparaData?.TP_DEPARA !== null ? deparaData?.TP_DEPARA : "",
      cd_multi_empresa:
        deparaData?.CD_MULTI_EMPRESA.toString() !== null
          ? deparaData?.CD_MULTI_EMPRESA.toString()
          : "",
      cd_depara_mv:
        deparaData?.CD_DEPARA_MV !== null ? deparaData?.CD_DEPARA_MV : "",
      ds_depara_mv:
        deparaData?.DS_DEPARA_MV !== null ? deparaData?.DS_DEPARA_MV : "",
      cd_depara_integra:
        deparaData?.CD_DEPARA_INTEGRA !== null
          ? deparaData?.CD_DEPARA_INTEGRA
          : "",
      ds_depara_integra:
        deparaData?.DS_DEPARA_INTEGRA !== null
          ? deparaData?.DS_DEPARA_INTEGRA
          : "",
      cd_sistema_integra:
        deparaData?.CD_SISTEMA_INTEGRA !== null
          ? deparaData?.CD_SISTEMA_INTEGRA
          : "",
      cd_dti_depara: deparaData?.CD_DTI_DEPARA,
    },
  });

  const isDisabled = methods.watch("tp_depara");

  async function handleSubmitForm(data: UpdateDeparaSchemaProps) {
    try {
      await updateDepara(data);
      toast.success("Depara criado com sucesso!");
      handleRefreshDeparas();
      handleCloseModal();
    } catch (err) {}
  }
  return {
    methods,
    handleSubmitForm,
    isDisabled,
  };
};
