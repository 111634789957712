import { Button, CircularProgress } from "@mui/material";
import moment from "moment";
import { Header } from "../../../Header";
import "./styles.scss";
import { useLotesUnifacisa } from "./useLotesUnifacisa";

export function LotesUnifacisa() {
  const {
    handleGetLotesRmMv,
    setCodLote,
    handleResponseRmMv,
    handleSendLotesMvRm,
    setMvRmObject,
    loadingRmMv,
    loadingMvRm,
    handleResponseMvRm,
  } = useLotesUnifacisa();
  return (
    <div className="lotes-container">
      <Header message={"Dashboard/Lotes Unifacisa"} showAtualizacao={true} />
      <main>
        <div className="lotes-main-card">
          <h2>Envio do Lote rm-mv</h2>
          <section className="lotes-main-card-section">
            <input
              type="text"
              onBlur={(e) => setCodLote(e.target.value)}
              placeholder="Informe os lotes"
              id="lotes-rmmv"
            />
            <Button variant="contained" onClick={() => handleGetLotesRmMv()}>
              {loadingRmMv ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                "Enviar"
              )}
            </Button>
          </section>
          {handleResponseRmMv !== "" && (
            <div className="lotes-main-card-message">
              <p>
                <b>Mensagem:</b> {handleResponseRmMv}
              </p>
            </div>
          )}
        </div>
        <div className="lotes-main-card">
          <h2>Envio do Lote mv-rm</h2>
          <section className="lotes-main-card-section">
            <div className="lotes-main-card-input-container">
              <p>Data Inicial</p>
              <input
                type="date"
                id="dtInicio-mvrm"
                onBlur={(e) =>
                  setMvRmObject((prevState) => {
                    return {
                      ...prevState,
                      dtInicio: moment(e.target.value).format("DD-MM-yyyy"),
                    };
                  })
                }
              />
            </div>
            <div className="lotes-main-card-input-container">
              <p>Data Final</p>
              <input
                type="date"
                id="dtFim-mvrm"
                onBlur={(e) =>
                  setMvRmObject((prevState) => {
                    return {
                      ...prevState,
                      dtFim: moment(e.target.value).format("DD-MM-yyyy"),
                    };
                  })
                }
              />
            </div>{" "}
            <Button variant="contained" onClick={() => handleSendLotesMvRm()}>
              {loadingMvRm ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                "Enviar"
              )}
            </Button>
          </section>
          {handleResponseMvRm !== "" && (
            <div className="lotes-main-card-message">
              <p>
                <b>Mensagem:</b> {handleResponseMvRm}
              </p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
